import { IconPropsWithSVGProps } from '../type';

const ToggleCheckButton = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <g clipPath='url(#clip0_1519_6105)'>
      <path
        d='M14.6667 8.00002C14.6667 4.31812 11.6819 1.33335 8.00004 1.33335C4.31814 1.33335 1.33337 4.31812 1.33337 8.00002C1.33337 11.6819 4.31814 14.6667 8.00004 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8.00002Z'
        stroke='#D3D4DC'
        strokeWidth='1.5'
      />
      <path
        d='M5.33337 8.50001C5.33337 8.50001 6.40004 9.10836 6.93337 10C6.93337 10 8.53337 6.50001 10.6667 5.33334'
        stroke='#D3D4DC'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1519_6105'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default ToggleCheckButton;
