import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  List,
  Item,
  ProfileImage,
  GreenCircle,
  ProfileImageWrapper,
  Title,
  ActiveItem,
  IconWrapper,
  SubItem,
  Dot,
  LogOutItem,
} from './style';
import { list, listProps, navProps } from '../../constants/navigation';
import { Flex, Span } from '../../styles/style';
import { subListProps } from '../../constants/navigation';
import Icon from '../Icon';

const AllIcon = ({ icon }: subListProps) => {
  return (
    <>
      <IconWrapper>
        {icon === 'DashBoard' ? (
          <Icon icon={icon} width={17} height={17} />
        ) : icon === 'ManageUser' ? (
          <Icon icon={icon} width={15} height={15} />
        ) : (
          <Icon icon={icon} width={13} height={13} />
        )}
      </IconWrapper>
    </>
  );
};
const Navigation = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState('');
  const [openNav, setOpenNav] = useState<string>('');
  const location = useLocation();
  const pathname = location.pathname;
  const logOut = async () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      localStorage.clear();
      window.location.reload();
    }
    // const response = await fetch(`${API_URL}/api/center/logout-submit`, {
    //   method: 'POST',
    //   credentials: 'include',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    // });

    // if (response.ok) {
    //   console.log('로그아웃 완료');
    // } else {
    // }
  };
  useEffect(() => {
    setOpenNav('');
  }, [navigate]);
  return (
    <>
      <Container>
        <List>
          <div>
            {list.map((v: listProps, index) => {
              return (
                <div key={index}>
                  <Title>{v.name}</Title>
                  {v.subUrl.map((sub: subListProps, index) => {
                    return (
                      <div key={index}>
                        {pathname.startsWith(`/${v.url}${sub.url}`) ? (
                          <>
                            <ActiveItem>
                              <AllIcon {...sub} />
                              <Span>{sub.name}</Span>
                            </ActiveItem>
                            {sub.subUrl2 &&
                              sub.subUrl2.map((item: navProps, index) => {
                                return (
                                  <div key={index}>
                                    <SubItem
                                      active={
                                        pathname ===
                                        `/${v.url}${sub.url}${item.url}`
                                      }
                                      onClick={() => {
                                        console.log(sub.url);
                                        if (sub.url === '/terms') {
                                          window.open(
                                            `/${v.url}${sub.url}${item.url}`,
                                            '_blank',
                                            'width=400,height=700'
                                          );
                                        } else {
                                          navigate(
                                            `/${v.url}${sub.url}${item.url}`
                                          );
                                        }
                                      }}
                                    >
                                      <Dot />
                                      {item.name}
                                    </SubItem>
                                  </div>
                                );
                              })}
                          </>
                        ) : (
                          <>
                            <Item
                              onClick={() => {
                                setSelected(`/${v.url}${sub.url}`);
                                sub.subUrl2
                                  ? setOpenNav(`/${v.url}${sub.url}`)
                                  : navigate(`/${v.url}${sub.url}`);
                              }}
                              isSelected={selected === `/${v.url}${sub.url}`}
                            >
                              <AllIcon {...sub} />
                              {sub.name}
                            </Item>
                            {`/${v.url}${sub.url}` === openNav &&
                              sub.subUrl2 && (
                                <>
                                  {sub.subUrl2.map((item: navProps, index) => {
                                    return (
                                      <SubItem
                                        key={index}
                                        active={
                                          pathname ===
                                          `/${v.url}${sub.url}${item.url}`
                                        }
                                        onClick={() => {
                                          console.log(sub.url);
                                          if (sub.url === '/terms') {
                                            window.open(
                                              `/${v.url}${sub.url}${item.url}`,
                                              '_blank',
                                              'width=400,height=700'
                                            );
                                          } else {
                                            navigate(
                                              `/${v.url}${sub.url}${item.url}`
                                            );
                                          }
                                        }}
                                      >
                                        <Dot />
                                        {item.name}
                                      </SubItem>
                                    );
                                  })}
                                </>
                              )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div>
            <LogOutItem onClick={logOut}>로그아웃</LogOutItem>
          </div>
        </List>
      </Container>
    </>
  );
};
export default Navigation;
