import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useAuthCheckStore } from '@/store/auth';
import LoginForm from '@/components/LoginForm';
import GlobalStyle from '@/GlobalStyle';
import DefaultLayout from '@/components/DefaultLayout';
import AccountListPage from '@/pages/AccountListPage';
import CenterListPage from '@/pages/CenterListPage';
import ManageCenterPage from '@/pages/ManageCenterPage';
import ManagePushPage from './pages/ManagePushPage';
import ManageMainPage from './pages/ManageMainPage';
import SessionListPage from './pages/SessionListPage';
import PostListPage from './pages/PostListPage';
function AuthenticatedRoutes() {
  return (
    <Routes>
      <Route element={<DefaultLayout />}>
        <Route path='/admin/user/list' element={<AccountListPage />} />
        <Route path='/admin/center/list' element={<CenterListPage />} />
        <Route path='/admin/center/manage' element={<ManageCenterPage />} />
        <Route path='/admin/main' element={<ManageMainPage />} />
        <Route path='/admin/push' element={<ManagePushPage />} />
        <Route path='/admin/session/list' element={<SessionListPage />} />
        <Route path='/admin/community/list' element={<PostListPage />} />
      </Route>
      <Route path='*' element={<Navigate to='/admin/user/list' />} />
    </Routes>
  );
}
function UnauthenticatedRoutes() {
  return (
    <Routes>
      <Route path='/' element={<LoginForm />} />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
}
function RoutingComponent() {
  const { authCheck } = useAuthCheckStore();
  if (authCheck) {
    return <AuthenticatedRoutes />;
  } else {
    return <UnauthenticatedRoutes />;
  }
}

function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path='*' element={<RoutingComponent />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
