import {
  Flex,
  CircleImage,
  NullDataBox,
  Span,
  SelectWrapper,
  Select,
  Option,
  SearchInput,
} from '@/styles/style';
import * as T from '@/styles/TableStyle';
import { getRoleDisplayName } from '@/constants/common';
import { ProfileType } from '@/constants/type';
import { useEffect, useState } from 'react';
import { ListInfoType, TableType } from '../type';
import { AccountDetailModal } from '../../Modals/AccountDetailModal';
import useCustomTable from '@/hooks/useCustomTable';
import { selectItem } from '../common';
import Pagination from '../../Pagination';
import { customFetch } from '@/constants/customFetch';

export const PushTable = () => {
  const [offset, setOffset] = useState<number>(0);
  const [list, setList] = useState<any[]>([]);
  const [listInfo, setListInfo] = useState<ListInfoType>();
  const [currentPagination, setCurrentPagination] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<'desc' | 'asc'>('desc');
  const [role, setRole] = useState<'all' | 'staff' | 'member' | 'owner'>('all');
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<ProfileType>();

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setOffset((currentPagination - 1) * 10);
  }, [currentPagination]);
  useEffect(() => {
    getData();
  }, [offset, role]);

  const getData = async () => {
    const response = await customFetch({
      url: `/api/admin/section/pushList`,
      body: {
        limit: 10,
        offset,
        orderBy,
      },
    });
    const { listInfo, pushlist } = response;
    if (listInfo) {
      setListInfo(listInfo);
    }
    if (pushlist) {
      setList(pushlist);
    } else {
      setList([]);
    }
  };

  return (
    <>
      <Flex justifyContents='space-between'>
        <Span color='var(--c100)'>
          총 {listInfo?.totalCount || 0}개의 데이터
        </Span>
      </Flex>
      <T.Container>
        <T.TableContainer>
          <T.Table>
            <T.THead>
              <T.Tr>
                <T.Th width='50px' textAlign='center'>
                  번호
                </T.Th>
                <T.Th>제목</T.Th>
                <T.Th>내용</T.Th>
                <T.Th textAlign='center'>발송인원</T.Th>
                <T.Th>상태</T.Th>
                <T.Th>딥링크</T.Th>
              </T.Tr>
            </T.THead>
            <T.TBody>
              {list.length > 0 ? (
                list.map((item, index) => {
                  return (
                    <T.Tr key={index}>
                      <T.Td textAlign='center'>{offset + index + 1}</T.Td>
                      <T.Td>{item.pushSublist[0].title}</T.Td>
                      <T.Td whiteSpace='wrap'>{item.pushSublist[0].body}</T.Td>
                      <T.Td textAlign='center'>{item.pushSubCount}명</T.Td>
                      <T.Td>
                        {item.pushSublist[0].isSuccess === 'success' && '성공'}
                        {item.pushSublist[0].isSuccess === 'failure' && '실패'}
                        {item.pushSublist[0].isSuccess === 'inProgress' &&
                          '진행중'}
                      </T.Td>
                      <T.Td whiteSpace='wrap'>
                        {item.pushSublist[0].deeplinkPath}
                      </T.Td>
                    </T.Tr>
                  );
                })
              ) : (
                <T.Tr>
                  <T.Td colSpan={6}>
                    <NullDataBox>데이터가 없습니다</NullDataBox>
                  </T.Td>
                </T.Tr>
              )}
            </T.TBody>
          </T.Table>
        </T.TableContainer>
        {listInfo && (
          <Pagination
            listInfo={listInfo}
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
          />
        )}
      </T.Container>
      {
        <AccountDetailModal
          data={selectedData}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      }
    </>
  );
};
