import { ScrollDiv } from '@/components/ItemCollection/style';
import { AccountTable } from '@/components/AccountTable';
import { Flex, Margin, Span } from '@/styles/style';
import { useEffect, useState } from 'react';
import * as S from '../style';
import { ModalType } from '../type';
import { ProfileTable_center } from '@/components/ProfileTable_center';
import { ImageContainer, ImageWarpper, ViewRegImage } from './style';

export const CenterDetailModal = ({
  data,
  modalShow,
  setModalShow,
}: ModalType) => {
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    if (modalShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalShow]);
  const handleClose = () => {
    setModalShow(false);
  };
  return (
    <>
      {modalShow && (
        <S.Background onClick={() => handleClose()}>
          <S.Container onClick={(e) => e.stopPropagation()}>
            <S.Header>센터 정보</S.Header>
            <Flex gap='50px' childFlex={1}>
              <S.InputContainer>
                <S.Title>이름</S.Title>
                <S.Input disabled value={data?.displayName} />
              </S.InputContainer>
              <S.InputContainer>
                <S.Title>주소</S.Title>
                <S.Input
                  disabled
                  value={data?.location.fullAddress.replace('|', ' ')}
                />
              </S.InputContainer>
            </Flex>
            <Flex gap='50px' childFlex={1}>
              <S.InputContainer>
                <S.Title>연락처</S.Title>
                <S.Input disabled value={data?.centerPhone} />
              </S.InputContainer>
              <S.InputContainer>
                <S.Title>가입일</S.Title>
                <S.Input disabled value={data?.createdAt?.split('T')[0]} />
              </S.InputContainer>
            </Flex>
            <Flex gap='50px' childFlex={1}>
              <S.InputContainer>
                <S.Title>사업자등록번호</S.Title>
                <S.Input disabled value={data?.regNumber} />
              </S.InputContainer>
              <S.InputContainer>
                <S.Title>사업자등록증</S.Title>
                <ViewRegImage
                  onClick={() =>
                    window.open(data?.businessRegImage?.mediumImage, '_blank')
                  }
                >
                  사업자등록증 보기
                </ViewRegImage>
              </S.InputContainer>
            </Flex>
            <S.InputContainer>
              <S.Title>대표사진</S.Title>
              <ImageContainer>
                {data?.bannerImages?.length > 0 &&
                  data?.bannerImages.map((image: any) => {
                    return (
                      <ImageWarpper>
                        <img
                          src={image.mediumImage}
                          alt='bannerImage'
                          onClick={() =>
                            window.open(image.mediumImage, '_blank')
                          }
                        />
                      </ImageWarpper>
                    );
                  })}
              </ImageContainer>
            </S.InputContainer>
            <S.InputContainer>
              <S.Title>가입한 유저 프로필 목록</S.Title>
              <ScrollDiv>
                <ProfileTable_center centerId={data.centerId} />
              </ScrollDiv>
            </S.InputContainer>
          </S.Container>
        </S.Background>
      )}
    </>
  );
};
