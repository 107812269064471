import * as Icons from '../components/Icon/Icons';
export type IconOption = keyof typeof Icons;
export type navProps = {
  url: string;
  name: string;
};
export type subListProps = {
  url: string;
  name: string;
  icon: IconOption;
  subUrl2?: navProps[];
};
export type listProps = {
  url: string;
  name: string;
  subUrl: subListProps[];
};
export const list: listProps[] = [
  {
    url: 'admin',
    name: '센터',
    subUrl: [
      {
        url: '/user',
        name: '회원관리',
        icon: 'ManageUser',
        subUrl2: [
          {
            url: '/list',
            name: '가입 계정 목록',
          },
        ],
      },
      {
        url: '/center',
        name: '센터관리',
        icon: 'ManageUser',
        subUrl2: [
          {
            url: '/list',
            name: '센터 목록',
          },
          {
            url: '/manage',
            name: '센터 신청 관리',
          },
        ],
      },
      {
        url: '/main',
        name: '메인 화면 관리',
        icon: 'DashBoard',
      },
      {
        url: '/push',
        name: '푸시 관리',
        icon: 'DashBoard',
      },
      {
        url: '/session/list',
        name: '세션 관리',
        icon: 'DashBoard',
      },
      {
        url: '/community/list',
        name: '커뮤니티 관리',
        icon: 'DashBoard',
      },
      // {
      //   url: '/session',
      //   name: '세션',
      //   icon: 'Session',
      // },
      // {
      //   url: '/feedback',
      //   name: '피드백',
      //   icon: 'Session',
      // },
      // {
      //   url: '/community',
      //   name: '커뮤니티',
      //   icon: 'Community',
      // },
    ],
  },
];
