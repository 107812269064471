import { useState, useEffect } from 'react';
import { CircleImage, NullDataBox } from '@/styles/style';
import * as T from '@/styles/TableStyle';
import { selectItem } from '../common';
import { TableType } from '../type';
import Pagination from '@/components/Pagination';
import useCustomTable from '@/hooks/useCustomTable';
const SportsTagTable = ({
  selectedItemList,
  setSelectedItemList,
  canCheck,
  showOnlySelected,
}: TableType) => {
  const [currentPagination, setCurrentPagination] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<'desc' | 'asc'>('desc');

  const { list, setList, listInfo, offset, setOffset } = useCustomTable({
    itemType: 'sportsTag',
    showOnlySelected,
    selectedItemList,
    currentPagination,
    orderBy,
  });

  return (
    <T.Container>
      <T.TableContainer>
        <T.Table>
          <T.THead>
            <T.Tr>
              {canCheck && <T.Th width='50px'>선택</T.Th>}
              <T.Th width='50px' textAlign='center'>
                번호
              </T.Th>
              <T.Th>태그</T.Th>
            </T.Tr>
          </T.THead>
          <T.TBody>
            {list.length > 0 ? (
              list.map((item, index) => {
                return (
                  <T.Tr key={index}>
                    {canCheck && selectedItemList && setSelectedItemList && (
                      <T.Td>
                        <input
                          type='checkbox'
                          checked={selectedItemList.some(
                            (selectedItem) => selectedItem.tagId === item.tagId
                          )}
                          onChange={() =>
                            selectItem(
                              item,
                              selectedItemList,
                              setSelectedItemList
                            )
                          }
                        />
                      </T.Td>
                    )}
                    <T.Td textAlign='center'>{offset + index + 1}</T.Td>
                    <T.Td>
                      <T.ProfileBox>
                        <CircleImage size='31px'>
                          <img
                            src={
                              item?.tagImage?.originalImage ||
                              '/defaultUserImage.png'
                            }
                            alt='프로필 이미지'
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.onerror = null; // 재귀적인 호출을 방지
                              target.src = '/defaultUserImage.png';
                            }}
                          />
                        </CircleImage>
                        {item.tagName}
                      </T.ProfileBox>
                    </T.Td>
                  </T.Tr>
                );
              })
            ) : (
              <T.Tr>
                <T.Td colSpan={3}>
                  <NullDataBox>데이터가 없습니다</NullDataBox>
                </T.Td>
              </T.Tr>
            )}
          </T.TBody>
        </T.Table>
      </T.TableContainer>
      {!showOnlySelected && listInfo && (
        <Pagination
          listInfo={listInfo}
          currentPagination={currentPagination}
          setCurrentPagination={setCurrentPagination}
        />
      )}
    </T.Container>
  );
};
export default SportsTagTable;
