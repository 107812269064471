import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const ThumbsUp_Active = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M1.33337 8.33346C1.33337 7.59708 1.93033 7.00012 2.66671 7.00012C3.77128 7.00012 4.66671 7.89555 4.66671 9.00012V11.6668C4.66671 12.7714 3.77128 13.6668 2.66671 13.6668C1.93033 13.6668 1.33337 13.0698 1.33337 12.3335V8.33346Z'
      stroke='#00FF99'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.3192 5.20427L10.1417 5.77766C9.99623 6.24751 9.9235 6.48243 9.97943 6.66797C10.0247 6.81807 10.124 6.94745 10.2594 7.03258C10.4268 7.1378 10.6799 7.1378 11.1862 7.1378H11.4555C13.1689 7.1378 14.0255 7.1378 14.4302 7.64497C14.4764 7.70293 14.5176 7.76456 14.5531 7.82921C14.8644 8.39487 14.5105 9.15686 13.8027 10.6808C13.1532 12.0794 12.8284 12.7786 12.2254 13.1902C12.1671 13.2301 12.1071 13.2676 12.0456 13.3029C11.4108 13.6668 10.6242 13.6668 9.05102 13.6668H8.70981C6.80389 13.6668 5.85093 13.6668 5.25884 13.0931C4.66675 12.5194 4.66675 11.5961 4.66675 9.74939V9.10033C4.66675 8.12986 4.66675 7.64462 4.83898 7.2005C5.0112 6.75637 5.34098 6.3912 6.00054 5.66085L8.72816 2.64048C8.79656 2.56473 8.83077 2.52685 8.86093 2.5006C9.14244 2.25562 9.5769 2.2832 9.82302 2.56167C9.84939 2.5915 9.8782 2.63338 9.93582 2.71713C10.026 2.84813 10.071 2.91364 10.1103 2.97854C10.462 3.55952 10.5684 4.24968 10.4073 4.90487C10.3893 4.97805 10.3659 5.0535 10.3192 5.20427Z'
      stroke='#00FF99'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default ThumbsUp_Active;
