import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Navigation from '../Navigation';
import { Flex } from '../../styles/style';
import Header from '../../components/Header';
import { useAuthCheckStore } from '../../store/auth';
interface DefaultLayoutProps {
  children?: React.ReactElement;
}
export default function DefaultLayout({ children }: DefaultLayoutProps) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <Header />
      <Flex flexDirection='row' margin='64px 0 0 0'>
        <Navigation />
        <Flex
          flexDirection='column'
          position='relative'
          width='100%'
          height='100%'
          margin='0 0 0 245px'
          padding='26px'
          overflow='auto'
        >
          <Outlet />
        </Flex>
      </Flex>
    </>
  );
}
