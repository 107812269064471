import { IconPropsWithSVGProps } from '../type';

const Session = (props: IconPropsWithSVGProps) => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_784_3583)'>
      <path
        d='M14 2.5C14 2.36739 13.9561 2.24021 13.878 2.14645C13.7998 2.05268 13.6938 2 13.5833 2H4.41667C4.30616 2 4.20018 2.05268 4.12204 2.14645C4.0439 2.24021 4 2.36739 4 2.5V3H14V2.5Z'
        fill='white'
      />
      <path
        d='M15 4.5C15 4.36739 14.9548 4.24021 14.8745 4.14645C14.7941 4.05268 14.6851 4 14.5714 4H3.42857C3.31491 4 3.2059 4.05268 3.12553 4.14645C3.04515 4.24021 3 4.36739 3 4.5V5H15V4.5Z'
        fill='white'
      />
      <path
        d='M15.1775 6H2.8225C2.60436 6 2.39515 6.09003 2.2409 6.25029C2.08666 6.41055 2 6.62791 2 6.85455V15.1455C2 15.3721 2.08666 15.5895 2.2409 15.7497C2.39515 15.91 2.60436 16 2.8225 16H15.1775C15.3956 16 15.6048 15.91 15.7591 15.7497C15.9133 15.5895 16 15.3721 16 15.1455V6.85455C16 6.62791 15.9133 6.41055 15.7591 6.25029C15.6048 6.09003 15.3956 6 15.1775 6ZM4.87 7.56818C5.12959 7.56818 5.38335 7.64816 5.59919 7.798C5.81503 7.94783 5.98325 8.16081 6.08259 8.40998C6.18193 8.65915 6.20792 8.93333 6.15728 9.19785C6.10664 9.46237 5.98163 9.70535 5.79808 9.89605C5.61452 10.0868 5.38066 10.2166 5.12606 10.2693C4.87146 10.3219 4.60756 10.2949 4.36773 10.1917C4.1279 10.0884 3.92292 9.91366 3.7787 9.68941C3.63448 9.46517 3.5575 9.20152 3.5575 8.93182C3.5575 8.57016 3.69578 8.22331 3.94192 7.96758C4.18806 7.71185 4.5219 7.56818 4.87 7.56818ZM14.25 14.1818H3.75L7.01375 10.7864C7.07193 10.7264 7.15055 10.6928 7.2325 10.6928C7.31445 10.6928 7.39307 10.7264 7.45125 10.7864L9.06125 12.4591L11.2794 10.0909C11.3376 10.031 11.4162 9.99731 11.4981 9.99731C11.5801 9.99731 11.6587 10.031 11.7169 10.0909L14.25 12.7227V14.1818Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_784_3583'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default Session;
