import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const FilterVertical = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='12'
    viewBox='0 0 14 12'
    fill='none'
  >
    <g id='Filter Vertical'>
      <path
        id='Vector 753'
        d='M1 2.66675H3'
        stroke='#D3D4DC'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector 754'
        d='M1 9.3335H5'
        stroke='#D3D4DC'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector 757'
        d='M11 9.3335L13 9.3335'
        stroke='#D3D4DC'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector 758'
        d='M9 2.66675L13 2.66675'
        stroke='#D3D4DC'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Rectangle 2129'
        d='M3 2.66675C3 2.04549 3 1.73486 3.10149 1.48984C3.23682 1.16313 3.49638 0.903567 3.82309 0.768242C4.06812 0.666748 4.37874 0.666748 5 0.666748C5.62126 0.666748 5.93188 0.666748 6.17691 0.768242C6.50362 0.903567 6.76318 1.16313 6.89851 1.48984C7 1.73486 7 2.04549 7 2.66675C7 3.288 7 3.59863 6.89851 3.84366C6.76318 4.17036 6.50362 4.42993 6.17691 4.56525C5.93188 4.66675 5.62126 4.66675 5 4.66675C4.37874 4.66675 4.06812 4.66675 3.82309 4.56525C3.49638 4.42993 3.23682 4.17036 3.10149 3.84366C3 3.59863 3 3.288 3 2.66675Z'
        stroke='#D3D4DC'
      />
      <path
        id='Rectangle 2130'
        d='M7 9.3335C7 8.71224 7 8.40161 7.10149 8.15658C7.23682 7.82988 7.49638 7.57032 7.82309 7.43499C8.06812 7.3335 8.37874 7.3335 9 7.3335C9.62126 7.3335 9.93188 7.3335 10.1769 7.43499C10.5036 7.57032 10.7632 7.82988 10.8985 8.15658C11 8.40161 11 8.71224 11 9.3335C11 9.95475 11 10.2654 10.8985 10.5104C10.7632 10.8371 10.5036 11.0967 10.1769 11.232C9.93188 11.3335 9.62126 11.3335 9 11.3335C8.37874 11.3335 8.06812 11.3335 7.82309 11.232C7.49638 11.0967 7.23682 10.8371 7.10149 10.5104C7 10.2654 7 9.95475 7 9.3335Z'
        stroke='#D3D4DC'
      />
    </g>
  </svg>
);

export default FilterVertical;
