import { Flex, Margin, Head, Container } from '@/styles/style';
import { AccountTable } from '@/components/AccountTable';
const AccountListPage = () => {
  return (
    <Flex>
      <Container>
        <Head>가입자 계정 목록</Head>
        <Margin margin='30px 0 0' />
        <AccountTable />
      </Container>
    </Flex>
  );
};
export default AccountListPage;
