import { useState } from 'react';
import { Flex, Margin, Head, Container, Button } from '@/styles/style';
import { AccountTable } from '@/components/AccountTable';
import AddPushModal from '@/components/Modals/AddPushModal';
import { PushTable } from '@/components/Tables/PushTable';
const ManagePushPage = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <Flex>
      <Container>
        <Head>푸시 목록</Head>
        <Flex justifyContents='right' gap='10px'>
          <Button
            onClick={() => {
              setModalShow(true);
            }}
          >
            추가하기
          </Button>
        </Flex>
        <PushTable />
      </Container>
      <AddPushModal modalShow={modalShow} setModalShow={setModalShow} />
    </Flex>
  );
};
export default ManagePushPage;
