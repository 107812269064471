const DotMenu = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <g id='dot menu'>
      <path
        id='Icon'
        d='M9.68734 9.68744H10.3123M9.68734 10.3124H10.3123M3.854 9.68744H4.479M3.854 10.3124H4.479M15.5207 9.68744H16.1457M15.5207 10.3124H16.1457M10.8334 9.99999C10.8334 10.4602 10.4603 10.8333 10 10.8333C9.5398 10.8333 9.16671 10.4602 9.16671 9.99999C9.16671 9.53975 9.5398 9.16666 10 9.16666C10.4603 9.16666 10.8334 9.53975 10.8334 9.99999ZM5.00004 9.99999C5.00004 10.4602 4.62694 10.8333 4.16671 10.8333C3.70647 10.8333 3.33337 10.4602 3.33337 9.99999C3.33337 9.53975 3.70647 9.16666 4.16671 9.16666C4.62694 9.16666 5.00004 9.53975 5.00004 9.99999ZM16.6667 9.99999C16.6667 10.4602 16.2936 10.8333 15.8334 10.8333C15.3731 10.8333 15 10.4602 15 9.99999C15 9.53975 15.3731 9.16666 15.8334 9.16666C16.2936 9.16666 16.6667 9.53975 16.6667 9.99999Z'
        stroke='#D3D4DC'
        strokeLinecap='round'
      />
    </g>
  </svg>
);

export default DotMenu;
