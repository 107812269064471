import { DEFAULT_ALERT_TEXT } from './alertText';
import { API_URL } from './api';
type customFetchType = {
  url: string;
  body?: any;
  method?: string;
};
export const customFetch = async ({
  url,
  body,
  method = 'POST',
}: customFetchType) => {
  try {
    const response = await fetch(`${API_URL}${url}`, {
      method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (response.status === 401) {
      alert('토큰이 만료되었습니다. 다시 로그인해주세요.');
      localStorage.clear();
      window.location.href = '/login';
      // 로그인 페이지로 리다이렉트나 토큰 갱신 로직을 여기에 추가
      return null;
    }

    if (response.ok) {
      return await response.json();
    } else {
      return {
        status: response.status, // 상태 코드 추가
        statusText: response.statusText, // 상태 텍스트 추가
      };
    }
  } catch (e) {
    alert(DEFAULT_ALERT_TEXT);
    return null;
  }
};
