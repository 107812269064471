import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Container, Header, AddButton, ScrollDiv } from './style';
import {
  Flex,
  Margin,
  Span,
  Button as MyButton,
  PointerDiv,
} from '@/styles/style';
import Icon from '@/components/Icon';
import { customFetch } from '@/constants/customFetch';
import * as TestData from '@/constants/testData';
import CenterTable from '../Tables/CenterTable';
import SportsTagTable from '../Tables/SportsTagTable';
import SessionTable from '../Tables/SessionTable';
import FeedbackTable from '../Tables/FeedbackTable';
import PostTable from '../Tables/PostTable';
type ItemCollectionType = {
  modalShow: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  sectionType: string; //'sportsTag' | 'center' | 'session' | 'feedback' | 'post'
  selectedItemList: string[];
  setSelectedItemList?: React.Dispatch<React.SetStateAction<string[]>>;
};
export const ItemCollection = ({
  modalShow,
  setModalShow,
  sectionType,
  selectedItemList,
  setSelectedItemList,
}: ItemCollectionType) => {
  const [dataList, setDataList] = useState<any[]>([]);
  useEffect(() => {
    if (sectionType === 'sportsTag') {
    }
    switch (sectionType) {
      case 'sportsTag':
        setDataList(TestData.sportsTagData.sportsTag);
        break;
      case 'center':
        setDataList(TestData.centerData.listCenter);
        break;
      case 'session':
        setDataList(TestData.sessionData.listCenter);
        break;
      case 'feedback':
        setDataList(TestData.feedbackData.feedbackList);
        break;
      case 'post':
        setDataList(TestData.postData.list);
        break;
    }
  }, [sectionType]);
  useEffect(() => {
    console.log(modalShow);
    if (modalShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalShow]);
  const handleClose = () => {
    setModalShow(false);
  };
  const submit = () => {};
  return (
    <>
      {modalShow && (
        <Container onClick={(e) => e.stopPropagation()}>
          <Flex justifyContents='space-between' margin='0 0 20px 0'>
            <Header>
              {sectionType === 'center' && '센터'}
              {sectionType === 'sportsTag' && '스포츠 태그'}
              {sectionType === 'session' && '세션'}
              {sectionType === 'feedback' && '피드백'}
              {sectionType === 'post' && '게시글'} 리스트
            </Header>
            <PointerDiv onClick={() => setModalShow(false)}>
              <Icon icon='X' color='var(--300)' width={15} height={15} />
            </PointerDiv>
          </Flex>
          <ScrollDiv>
            {sectionType === 'center' && (
              <CenterTable
                selectedItemList={selectedItemList}
                setSelectedItemList={setSelectedItemList}
                canCheck={true}
                showOnlySelected={false}
              />
            )}
            {sectionType === 'sportsTag' && (
              <SportsTagTable
                selectedItemList={selectedItemList}
                setSelectedItemList={setSelectedItemList}
                canCheck={true}
              />
            )}
            {sectionType === 'session' && (
              <SessionTable
                selectedItemList={selectedItemList}
                setSelectedItemList={setSelectedItemList}
                canCheck={true}
              />
            )}
            {sectionType === 'feedback' && (
              <FeedbackTable
                selectedItemList={selectedItemList}
                setSelectedItemList={setSelectedItemList}
                canCheck={true}
              />
            )}
            {sectionType === 'post' && (
              <PostTable
                selectedItemList={selectedItemList}
                setSelectedItemList={setSelectedItemList}
                canCheck={true}
              />
            )}
          </ScrollDiv>
        </Container>
      )}
    </>
  );
};
