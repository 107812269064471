import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const Alert = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <g id='Alert 01'>
      <circle
        id='Ellipse 1112'
        cx='7.99967'
        cy='7.99998'
        r='6.66667'
        stroke='#FF453A'
      />
      <path
        id='Vector'
        d='M7.99401 10H8'
        stroke='#FF453A'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector 2610'
        d='M8 8L8 5.33333'
        stroke='#FF453A'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default Alert;
