import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
const firebaseConfig = {
  apiKey: 'AIzaSyBPTPmpyKxRBUUeF-CvtpcwXAO3pjRBSzE',
  authDomain: 'admin-443ed.firebaseapp.com',
  projectId: 'admin-443ed',
  storageBucket: 'admin-443ed.appspot.com',
  messagingSenderId: '232964866452',
  appId: '1:232964866452:web:42ddb318e86611b3e24f81',
  measurementId: 'G-6K963VH0Y3',
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
