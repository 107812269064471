import { IconPropsWithSVGProps } from '../type';

const ToggleCheckButton = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <g clipPath='url(#clip0_1519_6128)'>
      <path
        d='M14.6667 7.99996C14.6667 4.31806 11.6819 1.33329 8.00004 1.33329C4.31814 1.33329 1.33337 4.31806 1.33337 7.99996C1.33337 11.6819 4.31814 14.6666 8.00004 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667 7.99996Z'
        stroke='#00FF99'
        strokeWidth='1.5'
      />
      <path
        d='M5.33337 8.49998C5.33337 8.49998 6.40004 9.10833 6.93337 9.99998C6.93337 9.99998 8.53337 6.49998 10.6667 5.33331'
        stroke='#00FF99'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1519_6128'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default ToggleCheckButton;
