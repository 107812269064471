import styled from 'styled-components';

type BackgroundType = {
  margin?: string;
  padding?: string;
};
export const Background = styled.div<BackgroundType>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  padding-top: 100px;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  overflow: auto;
`;
type ContainerType = {
  width?: string;
};
export const Container = styled.div<ContainerType>`
  width: ${({ width }) => (width ? width : '800px')};
  max-height: 1100px;
  background-color: #2c2d34;
  padding: 46px 36px;
  color: white;
  gap: 20px;
  overflow: auto;
`;
export const Header = styled.div`
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 40px;
`;
export const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.5px;
`;
type RequiredButtonType = {
  active: boolean;
};
export const RequiredButton = styled.div<RequiredButtonType>`
  width: 99px;
  height: 48px;
  background-color: transparent;
  border: ${({ active }) =>
    active ? '1px solid var(--primary)' : '1px solid white'};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const AddButton = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--primary);
  color: black;
  font-weight: bold;
  cursor: pointer;
`;
export const Input = styled.input`
  height: 50px;
  display: flex;
  padding: 16px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--grayscale-c-500, #8a8c99);
  color: rgb(138, 140, 153);
  width: 100%;
  background-color: transparent;
`;
export const InputContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin-bottom: 30px;
`;
export const DownIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  cursor: pointer;
`;
export const SearchBox = styled.div`
  background-color: rgb(34 34 39);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-top: -6px;
  height: 200px;
  overflow: auto;
`;

export const SearchRow = styled.div<{ borderBottom?: string }>`
  display: flex;
  gap: 10px;
  padding: 10px;
  font-size: 1.3rem;
  border-bottom: ${({ borderBottom }) => borderBottom};
  border: 5px;
  cursor: pointer;
  :hover {
    background-color: rgb(55 55 64);
  }
`;
export const SearchRow_H = styled(SearchRow)`
  :hover {
    background-color: transparent;
  }
`;
export const SearchItem = styled.div<{ flex?: 1 }>`
  width: 80px;
  text-align: center;
  flex: ${({ flex }) => flex};
  line-height: 15px;
`;
export const Select = styled.select`
  width: 100%;
  height: 50px;
  padding: 0 16px;
  background-color: transparent;
  color: rgb(138, 140, 153);
  border-radius: 10px;
  font-size: 1.3rem;
`;
export const Option = styled.option`
  font-size: 1.3rem;
  height: 50px;
  display: flex;
  padding: 16px;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--grayscale-c-500, #8a8c99);
  color: rgb(138, 140, 153);
  width: 100%;
  background-color: transparent;
`;
