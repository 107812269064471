import useCustomTable from '@/hooks/useCustomTable';
import { Button, Flex, NullDataBox, Span } from '@/styles/style';
import * as T from '@/styles/TableStyle';
import { useState } from 'react';
import { selectItem } from '../common';
import { TableType } from '../type';
import Pagination from '@/components/Pagination';
import { useLocation } from 'react-router-dom';
import { customFetch } from '@/constants/customFetch';
const PostTable = ({
  selectedItemList,
  setSelectedItemList,
  canCheck,
  showOnlySelected,
}: TableType) => {
  const [currentPagination, setCurrentPagination] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<'desc' | 'asc'>('desc');
  const location = useLocation();
  const pathname = location.pathname;

  const { list, setList, listInfo, offset, setOffset, getData } =
    useCustomTable({
      itemType: 'post',
      showOnlySelected,
      selectedItemList,
      currentPagination,
      orderBy,
    });

  const deletePost = async (postId: string) => {
    const response = await customFetch({
      url: `/api/admin/center/post/${postId}/delete`,
    });
    if (response.status && response.status !== 200) {
      alert('게시글 삭제에 실패했습니다');
    } else {
      await getData();
    }
  };
  return (
    <>
      <Flex justifyContents='space-between'>
        <Span color='var(--c100)'>
          총 {listInfo?.totalCount || 0}개의 데이터
        </Span>
      </Flex>
      <T.Container>
        <T.TableContainer>
          <T.Table>
            <T.THead>
              <T.Tr>
                {canCheck && <T.Th width='50px'>선택</T.Th>}
                <T.Th width='50px' textAlign='center'>
                  번호
                </T.Th>
                <T.Th>내용</T.Th>
                <T.Th textAlign='center'>좋아요수</T.Th>
                <T.Th textAlign='center'>댓글수</T.Th>
                <T.Th>작성일</T.Th>
              </T.Tr>
            </T.THead>
            <T.TBody>
              {list.length > 0 ? (
                list.map((item, index) => {
                  return (
                    <T.Tr key={index}>
                      {canCheck && selectedItemList && setSelectedItemList && (
                        <T.Td>
                          <input
                            type='checkbox'
                            checked={selectedItemList.some(
                              (selectedItem) =>
                                selectedItem.postId === item.postId
                            )}
                            onChange={() =>
                              selectItem(
                                item,
                                selectedItemList,
                                setSelectedItemList
                              )
                            }
                          />
                        </T.Td>
                      )}
                      <T.Td textAlign='center'>{offset + index + 1}</T.Td>
                      <T.Td whiteSpace='wrap'>
                        <Flex gap='10px' alignItems='center'>
                          {item.isNoti ? <T.NotiButton>공지</T.NotiButton> : ''}
                          {item.content}
                        </Flex>
                      </T.Td>
                      <T.Td textAlign='center'>{item.likeCount}</T.Td>
                      <T.Td textAlign='center'>{item.replyCount}</T.Td>
                      <T.Td>{item.createdAt?.split('T')[0]}</T.Td>
                      <T.Td textAlign='center'>
                        {item.isDeleted ? (
                          <Span color='var(--c300)'>삭제됨</Span>
                        ) : (
                          <Button
                            backgroundColor='var(--semantic-red)'
                            color='white'
                            border='none'
                            onClick={() =>
                              window.confirm(`게시글을 삭제하시겠습니까?`) &&
                              deletePost(item.postId)
                            }
                          >
                            삭제
                          </Button>
                        )}
                      </T.Td>
                    </T.Tr>
                  );
                })
              ) : (
                <T.Tr>
                  <T.Td colSpan={4}>
                    <NullDataBox>데이터가 없습니다</NullDataBox>
                  </T.Td>
                </T.Tr>
              )}
            </T.TBody>
          </T.Table>
        </T.TableContainer>
        {!showOnlySelected && listInfo && (
          <Pagination
            listInfo={listInfo}
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
          />
        )}
      </T.Container>
    </>
  );
};
export default PostTable;
