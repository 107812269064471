import styled from 'styled-components';
import { Button } from '@/styles/style';
export const SelectButtonContainer = styled.div`
  display: flex;
  margin-bottom: -15px;
  > button:not(:last-child) {
    border-right: none;
  }
`;
type SelectButtonType = {
  isActive: boolean;
};
export const SelectButton = styled(Button)<SelectButtonType>`
  width: 100px;
  border-radius: 0px;
  border-bottom: none;
  background-color: ${({ isActive }) =>
    isActive ? ' rgb(215, 218, 219)' : 'transparent'};
  color: ${({ isActive }) => (isActive ? 'black' : ' rgb(215, 218, 219)')};
  font-weight: ${({ isActive }) => isActive && 'bold'};
`;
export const WhiteBorderBox = styled.div`
  border: 1px solid rgb(215, 218, 219);
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
