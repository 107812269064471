import React, {
  Dispatch,
  ReactText,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { customFetch } from '@/constants/customFetch';
import { ListInfoType, SectionCategoryType } from '@/constants/type';
type Type = {
  itemType: SectionCategoryType;
  showOnlySelected: boolean | undefined;
  selectedItemList: any[] | undefined;
  currentPagination: number;
  setCurrentPagination?: React.Dispatch<React.SetStateAction<number>>;
  orderBy: 'asc' | 'desc';
  role?: 'all' | 'staff' | 'member' | 'owner';
  keyword?: string;
  setKeyword?: React.Dispatch<React.SetStateAction<string>>;
};
function useCustomTable({
  itemType,
  showOnlySelected,
  selectedItemList,
  currentPagination,
  setCurrentPagination,
  orderBy,
  role,
  keyword,
}: Type): {
  list: any[];
  setList: Dispatch<SetStateAction<any[]>>;
  listInfo: ListInfoType | undefined;
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
  getData: () => Promise<void>;
} {
  const [offset, setOffset] = useState<number>(0);
  const [list, setList] = useState<any[]>([]);
  const [listInfo, setListInfo] = useState<ListInfoType>();

  const getData = async () => {
    switch (itemType) {
      case 'account':
        getAccountData({
          setList,
          setListInfo,
          offset,
          orderBy,
          role,
        });
        break;
      case 'profile':
        getProfileData({
          setList,
          setListInfo,
          offset,
          orderBy,
          role,
          keyword,
        });
        break;
      case 'sportsTag':
        getSportsTagData({ setList, setListInfo, offset, orderBy });
        break;
      case 'center':
        getCenterData({ setList, setListInfo, offset, orderBy, role, keyword });
        break;
      case 'session':
        getSessionData({ setList, setListInfo, offset, orderBy });
        break;
      case 'feedback':
        getFeedbackData({ setList, setListInfo, offset, orderBy });
        break;
      case 'post':
        getPostData({ setList, setListInfo, offset, orderBy });
        break;
    }
  };

  // selected만 보여줄 때
  useEffect(() => {
    if (offset === 0) {
      setCurrentPagination && setCurrentPagination(1);
    }
    if (showOnlySelected && selectedItemList) {
      setList(selectedItemList);
      setListInfo({
        count: 0,
        isLastOffset: false,
        limit: 0,
        offset: 0,
        totalCount: selectedItemList.length,
      });
    } else if (!showOnlySelected) {
      getData();
    }
  }, [showOnlySelected, selectedItemList, offset]);
  // currentPage가 바뀌었을 때
  useEffect(() => {
    setOffset((currentPagination - 1) * 10);
  }, [currentPagination]);
  // role, keyword가 바뀌었을 때
  useEffect(() => {
    if (!(showOnlySelected && selectedItemList) && role) {
      getData();
      initOption();
    }
  }, [role, keyword]);

  const initOption = () => {
    setOffset(0);
    setCurrentPagination && setCurrentPagination(1);
  };
  return { list, setList, listInfo, offset, setOffset, getData }; // 필요한 값이나 메소드를 반환
}

export default useCustomTable;

type funcType = {
  setList: React.Dispatch<React.SetStateAction<any[]>>;
  setListInfo: Dispatch<SetStateAction<ListInfoType | undefined>>;
  offset: number;
  orderBy: 'asc' | 'desc';
  role?: 'all' | 'staff' | 'member' | 'owner';
};
type getAccountDataType = {
  nickname?: string;
  userKey?: string;
} & funcType;
const getAccountData = async ({
  setList,
  setListInfo,
  offset,
  orderBy,
  role,
  nickname,
  userKey,
}: getAccountDataType) => {
  const response = await customFetch({
    url: '/api/admin/center/joinList',
    body: {
      limit: 10,
      offset,
      orderBy,
      role,
      nickname,
      userKey,
    },
  });
  if (!response) return;
  const { listInfo, adminMemlist } = response;
  if (listInfo) {
    setListInfo(listInfo);
  }
  if (adminMemlist) {
    setList(adminMemlist);
  }
};
type getProfileDataType = {
  keyword?: string;
} & funcType;
const getProfileData = async ({
  setList,
  setListInfo,
  offset,
  orderBy,
  role,
  keyword,
}: getProfileDataType) => {
  const response = await customFetch({
    url: '/api/admin/center/joinSearch',
    body: {
      limit: 10,
      offset,
      orderBy,
      role,
      nickname: keyword !== '' ? keyword : null,
      userKey: null,
    },
  });
  const { listInfo, adminMemlist } = response;
  if (listInfo) {
    setListInfo(listInfo);
  }
  if (adminMemlist) {
    setList(adminMemlist);
  } else {
    setList([]);
  }
};
const getSportsTagData = async ({
  setList,
  setListInfo,
  offset,
  orderBy,
  role,
}: funcType) => {
  const response = await customFetch({
    url: '/api/admin/section/sportTagsList',
    body: {
      limit: 10,
      offset,
      orderBy,
    },
  });
  const { listInfo, sportsTags } = response;
  if (listInfo) {
    setListInfo(listInfo);
  }
  if (sportsTags) {
    setList(sportsTags);
  } else {
    setList([]);
  }
};
type getCenterDataType = {
  keyword?: string;
} & funcType;
const getCenterData = async ({
  setList,
  setListInfo,
  offset,
  orderBy,
  role,
  keyword,
}: getCenterDataType) => {
  const response = await customFetch({
    url: '/api/admin/center/allList',
    body: {
      limit: 10,
      offset,
      orderBy,
      role,
    },
  });
  const { listInfo, listCenter } = response;
  if (listInfo) {
    setListInfo(listInfo);
  }
  if (listCenter) {
    setList(listCenter);
  } else {
    setList([]);
  }
};

const getSessionData = async ({
  setList,
  setListInfo,
  offset,
  orderBy,
  role,
}: funcType) => {
  const response = await customFetch({
    url: '/api/admin/section/sessionList',
    body: {
      limit: 10,
      offset,
      orderBy,
    },
  });
  const { listInfo, sessionList } = response;
  if (listInfo) {
    setListInfo(listInfo);
  }
  if (sessionList) {
    setList(sessionList);
  } else {
    setList([]);
  }
};

const getFeedbackData = async ({
  setList,
  setListInfo,
  offset,
  orderBy,
}: funcType) => {
  const response = await customFetch({
    url: '/api/admin/section/feedbackList',
    body: {
      limit: 10,
      offset,
      orderBy,
    },
  });
  const { listInfo, adminFeedbackList } = response;
  if (listInfo) {
    setListInfo(listInfo);
  }
  if (adminFeedbackList) {
    setList(adminFeedbackList);
  } else {
    setList([]);
  }
};

const getPostData = async ({
  setList,
  setListInfo,
  offset,
  orderBy,
  role,
}: funcType) => {
  const response = await customFetch({
    url: '/api/admin/section/postList',
    body: {
      limit: 10,
      offset,
      orderBy,
    },
  });
  const { listInfo, list } = response;
  if (listInfo) {
    setListInfo(listInfo);
  }
  if (list) {
    setList(list);
  } else {
    setList([]);
  }
};
