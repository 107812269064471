import {
  Flex,
  CircleImage,
  NullDataBox,
  Span,
  SelectWrapper,
  Select,
  Option,
  SearchInput,
} from '@/styles/style';
import * as T from '@/styles/TableStyle';
import { getRoleDisplayName } from '@/constants/common';
import { ProfileType } from '@/constants/type';
import { useEffect, useState } from 'react';
import { ListInfoType, TableType } from '../Tables/type';
import { AccountDetailModal } from '../Modals/AccountDetailModal';
import useCustomTable from '@/hooks/useCustomTable';
import { selectItem } from '../Tables/common';
import Pagination from '../Pagination';
import { customFetch } from '@/constants/customFetch';
type Type =
  | {
      accountId: string;
    } & TableType;
export const ProfileTable_account = ({
  accountId,
  selectedItemList,
  setSelectedItemList,
  showOnlySelected,
  canCheck,
  canSearch,
  keyword,
  setKeyword,
}: Type) => {
  const [offset, setOffset] = useState<number>(0);
  const [list, setList] = useState<any[]>([]);
  const [listInfo, setListInfo] = useState<ListInfoType>();
  const [currentPagination, setCurrentPagination] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<'desc' | 'asc'>('desc');
  const [role, setRole] = useState<'all' | 'staff' | 'member' | 'owner'>('all');
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<ProfileType>();

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setOffset((currentPagination - 1) * 10);
  }, [currentPagination]);
  useEffect(() => {
    getData();
  }, [offset]);
  useEffect(() => {
    if (role) {
      getData();
      initOption();
    }
  }, [role]);
  const initOption = () => {
    setOffset(0);
    setCurrentPagination && setCurrentPagination(1);
  };
  const getData = async () => {
    const response = await customFetch({
      url: `/api/admin/member/${accountId}/view`,
      body: {
        limit: 10,
        offset,
        orderBy,
      },
    });
    const { listInfo, accountlist } = response;
    if (listInfo) {
      setListInfo(listInfo);
    }
    if (accountlist) {
      setList(accountlist);
    } else {
      setList([]);
    }
  };

  return (
    <>
      <Flex justifyContents='space-between'>
        <Span color='var(--c100)'>
          총{' '}
          {showOnlySelected ? selectedItemList?.length : listInfo?.totalCount}명
        </Span>
      </Flex>
      {canSearch && (
        <SearchInput
          placeholder='대상을 검색해주세요'
          onChange={(e) => setKeyword(e.target.value.trim())}
        />
      )}
      <T.Container>
        <T.TableContainer>
          <T.Table>
            <T.THead>
              <T.Tr>
                {canCheck && <T.Th width='50px'>선택</T.Th>}
                <T.Th width='50px' textAlign='center'>
                  번호
                </T.Th>
                <T.Th>이름</T.Th>
                <T.Th>센터명</T.Th>
                <T.Th>유저타입</T.Th>
                <T.Th>가입일</T.Th>
              </T.Tr>
            </T.THead>
            <T.TBody>
              {list.length > 0 ? (
                list.map((item, index) => {
                  return (
                    <T.Tr
                      key={index}
                      onClick={() => {
                        if (!canCheck) {
                          setModalShow(true);
                          setSelectedData(item);
                        }
                      }}
                    >
                      {canCheck && selectedItemList && setSelectedItemList && (
                        <T.Td>
                          <input
                            type='checkbox'
                            checked={selectedItemList.some(
                              (selectedItem) =>
                                selectedItem.userKey === item.userKey
                            )}
                            onChange={() =>
                              selectItem(
                                item,
                                selectedItemList,
                                setSelectedItemList
                              )
                            }
                          />
                        </T.Td>
                      )}
                      <T.Td textAlign='center'>{offset + index + 1}</T.Td>
                      <T.Td>
                        <T.ProfileBox>
                          <CircleImage size='31px'>
                            <img
                              src={
                                item.profileImage?.thumbnailImage ||
                                '/defaultUserImage.png'
                              }
                              alt='프로필 이미지'
                              onError={(e) => {
                                const target = e.target as HTMLImageElement;
                                target.onerror = null; // 재귀적인 호출을 방지
                                target.src = '/defaultUserImage.png';
                              }}
                            />
                          </CircleImage>
                          {item.nickname}
                        </T.ProfileBox>
                      </T.Td>
                      <T.Td>{item.displayName}</T.Td>
                      <T.Td>
                        <T.RoleBox role={item.role}>
                          {getRoleDisplayName(item.role)}
                        </T.RoleBox>
                      </T.Td>
                      <T.Td>{item.createdAt?.split('T')[0]}</T.Td>
                    </T.Tr>
                  );
                })
              ) : (
                <T.Tr>
                  <T.Td colSpan={6}>
                    <NullDataBox>데이터가 없습니다</NullDataBox>
                  </T.Td>
                </T.Tr>
              )}
            </T.TBody>
          </T.Table>
        </T.TableContainer>
        {!showOnlySelected && listInfo && (
          <Pagination
            listInfo={listInfo}
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
          />
        )}
      </T.Container>
      {
        <AccountDetailModal
          data={selectedData}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      }
    </>
  );
};
