import React from "react";

import { IconPropsWithSVGProps } from "../type";

const CheckBox = (props: IconPropsWithSVGProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3333 10C18.3333 5.39763 14.6023 1.66667 9.99996 1.66667C5.39759 1.66667 1.66663 5.39763 1.66663 10C1.66663 14.6024 5.39759 18.3333 9.99996 18.3333C14.6023 18.3333 18.3333 14.6024 18.3333 10Z"
      stroke="#8A8C99"
    />
    <path
      d="M6.66663 10.625C6.66663 10.625 7.99996 11.3854 8.66663 12.5C8.66663 12.5 10.6666 8.125 13.3333 6.66667"
      stroke="#8A8C99"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckBox;
