import { useEffect, useState } from 'react';
import * as S from '../style';
import * as CurrentS from './style';
import { ScrollDiv } from '../../ItemCollection/style';
import {
  Flex,
  Margin,
  Span,
  Button as MyButton,
  CheckBoxContainer,
} from '@/styles/style';
import CenterTable from '../../Tables/CenterTable';
import SportsTagTable from '../../Tables/SportsTagTable';
import SessionTable from '../../Tables/SessionTable';
import FeedbackTable from '../../Tables/FeedbackTable';
import PostTable from '../../Tables/PostTable';
import { ItemCollection } from '../../ItemCollection';
import { ModalType } from '../type';
import { customFetch } from '@/constants/customFetch';

export const AddSectionModal = ({ modalShow, setModalShow }: ModalType) => {
  // Form data
  const [title, setTitle] = useState<string>('');
  const [subTitle, setSubTitle] = useState<string>('');
  const [sectionType, setSectionType] = useState<string>('sportsTag');
  const [itemExposeType, setItemExposeType] = useState<string>('manual');
  const [itemExposeCount, setItemExposeCount] = useState<number>(0);
  // status
  const [itemCollectionModalShow, setItemCollectionModalShow] = useState(false);
  const [selectedItemList, setSelectedItemList] = useState<any[]>([]);
  const [randomSessionType, setRandomSessionType] = useState<'public' | 'join'>(
    'public'
  );
  useEffect(() => {
    setItemExposeType('manual');
    setSelectedItemList([]);
    setItemExposeCount(0);
  }, [sectionType]);
  useEffect(() => {
    if (itemExposeType !== 'manual') setItemCollectionModalShow(false);
  }, [itemExposeType]);
  useEffect(() => {
    console.log(modalShow);
    if (modalShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalShow]);
  const handleClose = () => {
    setModalShow(false);
    setInitialModal();
  };
  const setInitialModal = () => {
    setSelectedItemList([]);
    setSectionType('sportsTag');
    setItemExposeType('manual');
    setItemExposeCount(0);
    setItemCollectionModalShow(false);
    setSelectedItemList([]);
  };
  const submit = async () => {
    if (!checkValidate()) {
      return;
    }
    const items = selectedItemList.map((item) => {
      let itemId;
      switch (sectionType) {
        case 'sportsTag':
          itemId = item.tagId;
          break;
        case 'center':
          itemId = item.centerId;
          break;
        case 'session':
          itemId = item.sessionId;
          break;
        case 'feedback':
          itemId = item.feedbackId;
          break;
        case 'post':
          itemId = item.postId;
          break;
        default:
          break;
      }
      return {
        type: sectionType,
        itemId,
      };
    });
    const response = await customFetch({
      url: '/api/admin/section/save',
      body: {
        sectionsList: [
          {
            title,
            subtitle: subTitle,
            type: sectionType,
            items,
            isHidden: false,
            itemExposeType,
            itemExposeCount:
              itemExposeType === 'manual' ? items.length : itemExposeCount,
            sessionType:
              itemExposeType === 'randomPublicSession'
                ? randomSessionType
                : null,
          },
        ],
      },
    });
    const result = response;
    if (!result) return;
    window.location.reload();
  };
  const checkValidate = () => {
    if (title === '') {
      alert('타이틀을 입력해주세요');
      return false;
    }
    if (itemExposeType === 'manual' && selectedItemList.length < 1) {
      alert('아이템을 추가해주세요');
      return false;
    }
    if (itemExposeType.startsWith('random') && itemExposeCount < 1) {
      alert('노출 개수를 입력해주세요');
      return false;
    }
    return true;
  };
  return (
    <>
      {modalShow && (
        <S.Background onClick={() => handleClose()}>
          <S.Container width='500px'>
            <CurrentS.InnerContainer onClick={(e) => e.stopPropagation()}>
              <S.Header>섹션 추가</S.Header>

              <S.InputContainer>
                <S.Title>타이틀*</S.Title>
                <S.Input
                  placeholder='타이틀을 입력하세요.'
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </S.InputContainer>

              <S.InputContainer>
                <S.Title>서브 타이틀</S.Title>
                <S.Input
                  placeholder='서브 타이틀을 입력하세요.'
                  value={subTitle}
                  onChange={(e) => {
                    setSubTitle(e.target.value);
                  }}
                />
              </S.InputContainer>

              <S.InputContainer>
                <S.Title>섹션 타입*</S.Title>
                <S.Select onChange={(e) => setSectionType(e.target.value)}>
                  <S.Option value='sportsTag' selected>
                    스포츠 태그
                  </S.Option>
                  <S.Option value='center'>센터</S.Option>
                  <S.Option value='session'>세션</S.Option>
                  <S.Option value='feedback'>피드백</S.Option>
                  <S.Option value='post'>커뮤니티 게시글</S.Option>
                </S.Select>
              </S.InputContainer>

              <S.InputContainer>
                <S.Title>노출 타입*</S.Title>
                <S.Select onChange={(e) => setItemExposeType(e.target.value)}>
                  <S.Option disabled>노출 타입을 선택하세요.</S.Option>
                  <S.Option value='manual'>매뉴얼</S.Option>
                  {sectionType === 'center' && (
                    <S.Option value='randomCenter'>랜덤센터</S.Option>
                  )}
                  {sectionType === 'session' && (
                    <S.Option value='randomPublicSession'>랜덤세션</S.Option>
                  )}
                </S.Select>
              </S.InputContainer>
              {itemExposeType === 'randomPublicSession' &&
                sectionType === 'session' && (
                  <Flex gap='15px' margin='0 0 30px 0'>
                    <CheckBoxContainer>
                      <input
                        type='checkbox'
                        checked={'public' === randomSessionType}
                        onChange={() => setRandomSessionType('public')}
                      />
                      공개 세션
                    </CheckBoxContainer>
                    <CheckBoxContainer>
                      <input
                        type='checkbox'
                        checked={'join' === randomSessionType}
                        onChange={() => setRandomSessionType('join')}
                      />
                      가입한 세션
                    </CheckBoxContainer>
                  </Flex>
                )}
              {itemExposeType !== 'manual' && (
                <>
                  <S.InputContainer>
                    <S.Title>노출 개수</S.Title>
                    <S.Input
                      placeholder='노출 개수를 입력하세요.'
                      type='number'
                      defaultValue={itemExposeCount}
                      value={itemExposeCount}
                      onChange={(e) => {
                        if (Number(e.target.value) > 10) {
                          alert('10개 이상 추가할 수 없습니다');
                        } else {
                          setItemExposeCount(Number(e.target.value));
                        }
                      }}
                    />
                  </S.InputContainer>
                </>
              )}
              {itemExposeType === 'manual' && (
                <>
                  <Flex flexDirection='column'>
                    <Flex
                      position='relative'
                      justifyContents='space-between'
                      alignItems='center'
                    >
                      <S.Title>아이템 리스트</S.Title>
                      <MyButton
                        onClick={() => setItemCollectionModalShow(true)}
                      >
                        + 추가하기
                      </MyButton>
                    </Flex>

                    <ScrollDiv>
                      {sectionType === 'center' && (
                        <CenterTable
                          selectedItemList={selectedItemList}
                          setSelectedItemList={setSelectedItemList}
                          canCheck={false}
                          showOnlySelected={true}
                        />
                      )}
                      {sectionType === 'sportsTag' && (
                        <SportsTagTable
                          selectedItemList={selectedItemList}
                          setSelectedItemList={setSelectedItemList}
                          canCheck={false}
                          showOnlySelected={true}
                        />
                      )}
                      {sectionType === 'session' && (
                        <SessionTable
                          selectedItemList={selectedItemList}
                          setSelectedItemList={setSelectedItemList}
                          canCheck={false}
                          showOnlySelected={true}
                        />
                      )}
                      {sectionType === 'feedback' && (
                        <FeedbackTable
                          selectedItemList={selectedItemList}
                          setSelectedItemList={setSelectedItemList}
                          canCheck={false}
                          showOnlySelected={true}
                        />
                      )}
                      {sectionType === 'post' && (
                        <PostTable
                          selectedItemList={selectedItemList}
                          setSelectedItemList={setSelectedItemList}
                          canCheck={false}
                          showOnlySelected={true}
                        />
                      )}
                    </ScrollDiv>
                  </Flex>
                  <Margin margin='0 0 30px 0' />
                </>
              )}

              <S.AddButton onClick={() => submit()}>추가하기</S.AddButton>
            </CurrentS.InnerContainer>
          </S.Container>
          <ItemCollection
            modalShow={itemCollectionModalShow}
            setModalShow={setItemCollectionModalShow}
            sectionType={sectionType}
            selectedItemList={selectedItemList}
            setSelectedItemList={setSelectedItemList}
          />
        </S.Background>
      )}
    </>
  );
};
