import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const Message = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 8.98774 2.23868 9.91973 2.6615 10.7415C2.85884 11.125 2.91962 11.5728 2.7668 11.9762L2.17104 13.5486C2.08841 13.7667 2.24954 14 2.48275 14H8Z'
      stroke='#D3D4DC'
    />
  </svg>
);

export default Message;
