import React from 'react';

import { IconPropsWithSVGProps } from '../type';

const ArrowDown_line = (props: IconPropsWithSVGProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='6'
    height='10'
    viewBox='0 0 6 10'
    fill='none'
  >
    <g id='Arrow'>
      <path
        id='Vector'
        d='M3 8.5L3 1'
        stroke='#D3D4DC'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector 6907'
        d='M5.5 6.5L3.70711 8.29289C3.37377 8.62623 3.20711 8.79289 3 8.79289C2.79289 8.79289 2.62623 8.62623 2.29289 8.29289L0.5 6.5'
        stroke='#D3D4DC'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);

export default ArrowDown_line;
