import { useState, useEffect } from 'react';
import { customFetch } from '@/constants/customFetch';
import { Container, Flex } from '@/styles/style';
import { Head, Margin, Button } from '@/styles/style';
import { SectionTable } from '@/components/Tables/SectionTable';
import { AddSectionModal } from '@/components/Modals/AddSectionModal';
import { ItemCollection } from '@/components/ItemCollection';
const ManageMainPage = () => {
  const [modalShow, setModalShow] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [changeOrder, setChangeOrder] = useState<
    { id: string; index: number }[]
  >([{ id: '', index: 0 }]);

  const checkValidate = () => {
    const ids = changeOrder.map((v) => v.index);
    const idsSet = new Set(ids);
    if (idsSet.size === ids.length) return true;
    else return false;
  };
  const submit = async () => {
    if (!checkValidate()) {
      alert('순서 번호를 겹치지 않게 입력해주세요');
      return;
    }
    const response = await customFetch({
      url: '/api/admin/section/changeOrder',
      body: {
        changeOrder,
      },
    });

    if (response.status && response.status !== 200) {
      alert('저장에 문제가 발생하였습니다.');
    } else {
      window.location.reload();
    }
    setIsEdit(false);
  };
  return (
    <Flex>
      <Container>
        <Head>메인 화면 관리</Head>
        <Flex justifyContents='right' gap='10px'>
          {isEdit ? (
            <Button
              backgroundColor='var(--primary)'
              border='none'
              color='black'
              fontWeight='bold'
              onClick={submit}
            >
              저장하기
            </Button>
          ) : (
            <Button onClick={() => setIsEdit(true)}>순서 변경하기</Button>
          )}
          <Button
            onClick={() => {
              setModalShow(true);
            }}
          >
            추가하기
          </Button>
        </Flex>
        <SectionTable
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          changeOrder={changeOrder}
          setChangeOrder={setChangeOrder}
        />
      </Container>

      <AddSectionModal modalShow={modalShow} setModalShow={setModalShow} />
    </Flex>
  );
};

export default ManageMainPage;
