import useCustomTable from '@/hooks/useCustomTable';
import { NullDataBox } from '@/styles/style';
import * as T from '@/styles/TableStyle';
import { useState } from 'react';
import Pagination from '@/components/Pagination';
import { selectItem } from '../common';
import { TableType } from '../type';
const FeedbackTable = ({
  selectedItemList,
  setSelectedItemList,
  canCheck,
  showOnlySelected,
}: TableType) => {
  const [currentPagination, setCurrentPagination] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<'desc' | 'asc'>('desc');

  const { list, setList, listInfo, offset, setOffset } = useCustomTable({
    itemType: 'feedback',
    showOnlySelected,
    selectedItemList,
    currentPagination,
    orderBy,
  });
  return (
    <T.Container>
      <T.TableContainer>
        <T.Table>
          <T.THead>
            <T.Tr>
              {canCheck && <T.Th width='50px'>선택</T.Th>}
              <T.Th width='50px' textAlign='center'>
                번호
              </T.Th>
              <T.Th>요청내용</T.Th>
              <T.Th>상태</T.Th>
            </T.Tr>
          </T.THead>

          <T.TBody>
            {list.length > 0 ? (
              list.map((item, index) => {
                return (
                  <T.Tr key={index}>
                    {canCheck && selectedItemList && setSelectedItemList && (
                      <T.Td>
                        <input
                          type='checkbox'
                          checked={selectedItemList.some(
                            (selectedItem) =>
                              selectedItem.feedbackId === item.feedbackId
                          )}
                          onChange={() =>
                            selectItem(
                              item,
                              selectedItemList,
                              setSelectedItemList
                            )
                          }
                        />
                      </T.Td>
                    )}
                    <T.Td textAlign='center'>{offset + index + 1}</T.Td>
                    <T.Td whiteSpace='wrap'>{item.feedbackContent}</T.Td>
                    <T.Td>{item.feedbackStatus}</T.Td>
                  </T.Tr>
                );
              })
            ) : (
              <T.Tr>
                <T.Td colSpan={2}>
                  <NullDataBox>데이터가 없습니다</NullDataBox>
                </T.Td>
              </T.Tr>
            )}
          </T.TBody>
        </T.Table>
      </T.TableContainer>
      {!showOnlySelected && listInfo && (
        <Pagination
          listInfo={listInfo}
          currentPagination={currentPagination}
          setCurrentPagination={setCurrentPagination}
        />
      )}
    </T.Container>
  );
};
export default FeedbackTable;
